import { Popover, Transition } from '@headlessui/react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import authService from '../../services/auth.service';
import { toggleSidebar } from '../../store/slices/components.slice';
import Image from '../Image';

import { BuildingStoreIcon, LogOutIcon, MailIcon, PinIcon, ShareIcon, ShoppingBagIcon, UndoIcon, UserIcon } from '@iconicicons/react';
import { Drawer } from 'antd';
import Mail from '../../assets/images/icons8-mail-48.png';
import WhatsApp from '../../assets/images/icons8-whatsapp-48.png';
import tokenService from '../../services/token.service';
import { saveAddresses, saveDeliverToAddress, updateUser } from '../../store/slices/user.slice';
import { classNames, isNewPage, isSessionExist } from '../../utils';
import TextButton from '../Buttons/TextButton/TextButton';
import { Form } from '../Form';
import NubesLogo from '../Logo';
import NubesLogoV2 from '../LogoV2/LogoV2';
import { ModalContact } from '../Modal';
import Tooltip from '../Tooltip';
import IcSearch from '../../assets/images/ic-search.svg';
import IcChevronLeft from '../../assets/images/ic-chevron-left.svg';
import { useMediaQuery } from '../../hooks/hooks';

const Layout = {
  Header: () => {
    const [expandAvatar, setExpandAvatar] = useState(false);
    const [expandMessages, setExpandMessages] = useState(false);
    const { vendorApi } = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const messagesNode = useRef();
    const avatarNode = useRef();

    const messages = [
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: '10 mins ago', subject: 'New screen created', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: '10 mins ago', subject: 'New screen created', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' }
    ];

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleMessagesIconClick);
      document.addEventListener('mousedown', handleAvatarClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleMessagesIconClick);
        document.removeEventListener('mousedown', handleAvatarClick);
      };
    }, []);

    const handleMessagesIconClick = (e) => {
      if (messagesNode.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpandMessages(false);
    };

    const handleAvatarClick = (e) => {
      if (avatarNode.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpandAvatar(false);
    };

    const handleLogout = () => {
      dispatch(saveDeliverToAddress({}));
      authService.logout(history);
    };

    return (
      <>
        <header className="flex-shrink-0">
          <div className="flex items-center h-16 border-b border-gray-200 shadow-md bg-white">
            <div className="px-6 sm:px-8 w-full">
              <div className="flex justify-between items-center py-3">
                <div className="flex flex-1 min-w-0">
                  <button type="button" onClick={() => dispatch(toggleSidebar(true))} className="text-gray-600 lg:hidden">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"></path>
                    </svg>
                  </button>
                </div>
                <div className="ml-6 flex-shrink-0 flex items-center">
                  <div className="hidden items-center space-x-5 mr-5">
                    <div className="z-10 relative inline-block text-left" ref={messagesNode}>
                      <div>
                        <button type="button" onClick={() => setExpandMessages(!expandMessages)} className="relative flex items-center focus:outline-none">
                          <svg
                            className="w-7 h-7 text-gray-500 hover:text-gray-600 cursor-pointer"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            ></path>
                          </svg>
                          {messages.length > 0 && (
                            <span className="flex absolute h-2 w-2 top-2 right-2 -mt-1 -mr-1 ring-2 rounded ring-white">
                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                              <span className="relative inline-flex rounded-full h-2 w-2 bg-primary-500"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <Transition
                        show={expandMessages}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="origin-top-right right-0 absolute mx-[-3.8rem] sm:mx-0 mt-3 w-[24rem] sm:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="flex items-center justify-between text-sm px-4 py-2 border-b font-medium">
                            <span>Messages</span>
                            {messages.length > 0 && (
                              <button type="button" className="text-primary-600 hover:text-primary-700 font-medium">
                                Mark all as read
                              </button>
                            )}
                          </div>
                          <div>
                            <ul className="overflow-scroll h-[20rem] sm:h-[15rem] divide-y">
                              {messages.length > 0 ? (
                                messages.map((toast, index) => (
                                  <li key={index}>
                                    <div className="p-3 text-sm flex items-start hover:bg-primary-100 cursor-pointer">
                                      {/* <svg className="w-6 h-6 mr-2.5 flex-none text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> */}
                                      <div className="ml-1 mr-6">
                                        <span className="font-medium">{toast.subject}</span>
                                        <p className="mt-1 text-gray-500 leading-relaxed">{toast.message}</p>
                                        <p className="mt-2 text-xs text-primary-700">{toast.created}</p>
                                      </div>
                                      <span className="ml-auto cursor-pointer">
                                        <svg
                                          className="w-5 h-5 text-primary-600 hover:text-primary-700"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                    {index === messages.length - 1 && <div className="py-3 text-xs text-center text-gray-500">You're up to date 🎉</div>}
                                  </li>
                                ))
                              ) : (
                                <div className="flex flex-col items-center justify-center h-full text-gray-700">
                                  <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                                    ></path>
                                  </svg>
                                  <span className="mt-6 font-bold">You're all caught up!</span>
                                  <p className="mt-2 text-sm font-medium text-gray-500">There are no new messages unread</p>
                                </div>
                              )}
                            </ul>
                          </div>
                          <button
                            type="button"
                            className="w-full py-3 flex items-center justify-center font-medium text-sm bg-gray-50 text-primary-600 hover:bg-primary-100 border-t"
                          >
                            View all messages
                          </button>
                        </div>
                      </Transition>
                    </div>
                  </div>
                  <div className="flex w-full items-center space-x-3">
                    <div className="z-20 relative inline-block text-left" ref={avatarNode}>
                      <button type="button" onClick={() => setExpandAvatar(!expandAvatar)} className="flex items-center">
                        <Image className="h-8 w-8 rounded-full object-cover" src={vendorApi?.profilePicture?.path?.m} alt="" />
                      </button>
                      <Transition
                        show={expandAvatar}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="absolute right-0 w-56 mt-2 origin-top-right text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          id="user-panel-menu-items"
                          role="menu"
                          tabIndex="0"
                        >
                          <div className="px-1 py-1" role="none">
                            <Link to="/">
                              <button
                                className="group flex text-gray-800 rounded-md items-center w-full px-2 py-2 text-sm hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                                tabIndex="-1"
                              >
                                <svg className="w-5 h-5 mr-2 group-hover:text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <UndoIcon />
                                </svg>
                                Return as Customer
                              </button>
                            </Link>
                          </div>
                          <div className="px-1 py-1" role="none">
                            <button
                              onClick={handleLogout}
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <svg className="w-5 h-5 mr-2 group-hover:text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                ></path>
                              </svg>
                              Log out
                            </button>
                          </div>
                          <div className="hidden px-1 py-1" role="none">
                            {/*
                              Another section goes here
                              Note: Remove 'hidden' if you wish to use this section
                             */}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  },
  Sidebar: ({ path, vendorData }) => {
    const [menuCollapse, setMenuCollapse] = useState();
    const sidebarToggled = useSelector((state) => state.components.sidebarToggled);
    const dispatch = useDispatch();
    const location = useLocation();

    const navigations = [
      { title: 'Home', path: '/store' },
      { title: 'Profile', path: '/store/profile' },
      { title: 'Products', path: '/store/products' },
      { title: 'Orders', path: '/store/orders' },
      { title: 'Quote', path: '/store/quote' },
      // {
      //   title: 'Reports',
      //   path: '/store/reports',
      //   submenu: [
      //     { title: 'Sales', path: '/store/reports/sales' }
      //   ]
      // }
    ];

    if (vendorData?.data?.multiLocation === true) {
      navigations.push({ title: 'Addresses', path: '/store/addresses' });
    }
    navigations.push({ title: 'Return as Customer', path: '/' });

    const node = useRef();

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleDropdownClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleDropdownClick);
      };
    });

    const handleDropdownClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        delayCloseSideBar();
        return;
      }
      // outside click
      dispatch(toggleSidebar(false));
    };

    const delayCloseSideBar = () => {
      console.log('dispatch(toggleSidebar(false)) ', 'masuk');
      setTimeout(() => {
        dispatch(toggleSidebar(false));
      }, 100);
    };

    const handleMenuCollapse = (path) => {
      if (menuCollapse === path) {
        setMenuCollapse(null);
      } else {
        setMenuCollapse(path);
      }
    };

    return (
      <>
        <div className={`fixed z-30 inset-0 bg-black bg-opacity-70 transition-opacity lg:hidden ${sidebarToggled ? 'block' : 'hidden'}`}></div>
        <nav
          ref={node}
          className={`fixed z-30 inset-y-0 left-0 w-64 px-8 py-3 bg-coolGray-50 border-r border-gray-300 overflow-auto transform transition lg:static lg:inset-auto lg:translate-x-0 ${
            sidebarToggled ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className="space-y-8">
            <div className="-mx-3 flex items-center justify-between h-14">
              <div className="flex items-center">
                <Link to="/">
                  <NubesLogo className="flex-shrink-0 h-8 w-auto" />
                </Link>
                <span className="hidden sm:block ml-4 text-2xl font-logo text-gray-700 uppercase">My Store</span>
              </div>
              <button type="button" onClick={() => dispatch(toggleSidebar(false))} className="text-gray-700 lg:hidden">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="mt-2 -mx-3">
              {navigations.map((nav, index) =>
                nav.submenu ? (
                  <>
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleMenuCollapse(nav.path)}
                      className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm w-full text-gray-700 hover:text-primary-600`}
                    >
                      <span className={`${nav.submenu ? 'font-semibold' : 'font-normal'}`}>{nav.title}</span>
                      {menuCollapse !== nav.path ? (
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                    </button>
                    <div className={menuCollapse === nav.path ? 'block' : 'hidden'}>
                      {nav.submenu.map((menu, key) => (
                        <Link
                          key={key}
                          to={menu.path}
                          className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm ${
                            `/${path.split('/')[1]}/${path.split('/')[2]}` === menu.path || path === menu.path
                              ? 'bg-primary-100 text-primary-600 font-semibold'
                              : 'text-gray-500 hover:text-primary-600 hover:bg-primary-100'
                          }`}
                        >
                          <span>{menu.title}</span>
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    key={index}
                    to={nav.path}
                    className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm ${
                      `/${path.split('/')[1]}/${path.split('/')[2]}` === nav.path || path === nav.path
                        ? 'bg-primary-100 text-primary-600 font-semibold'
                        : 'text-gray-500 hover:text-primary-600 hover:bg-primary-100'
                    }`}
                  >
                    <span>{nav.title}</span>
                  </Link>
                ),
              )}
            </div>
          </div>
        </nav>
      </>
    );
  },
  Content: ({ children }) => {
    return (
      <>
        <main className="flex-1 overflow-auto bg-gray-50 h-screen lg:h-auto text-gray-700 p-6 sm:p-8">{children}</main>
      </>
    );
  },
  Public: ({ children, path, vendorData, tooltipAddressValue }) => {
    const [expandAvatar, setExpandAvatar] = useState(false);
    const [expandPublicMenu, setExpandPublicMenu] = useState(false);
    const [expandMenu, setExpandMenu] = useState(false);
    let [addresses, setAddresses] = useState([]);
    const avatarNode = useRef();
    const PublicMenuNode = useRef();
    const dispatch = useDispatch();
    const { userApi } = useSelector((state) => state);
    const [modalOpen, setModulOpen] = useState(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const history = useHistory();
    const loginUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/login?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;
    const signUpUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/signup?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;

    const location = useLocation();
    const [formData, setFormData] = useState(location.state);
    const [layoutShow, setLayoutShow] = useState(false);
    const showBackButton = ['/search'].some((value) => value === path);

    useEffect(() => {
      if (path.split('/')[1] !== 'store' || path == '/store/register') {
        if (userApi?.addresses?.length > 0) setAddresses(userApi.addresses);
        if (tokenService.getLocalAccessToken()) setFormData(vendorData);
        setLayoutShow(true);
        document.addEventListener('mousedown', handleAvatarClick);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener('mousedown', handleAvatarClick);
        };
      } else {
        setLayoutShow(false);
      }
    }, [userApi]);

    const handleAvatarClick = (e) => {
      if (avatarNode?.current?.contains(e.target)) {
        // inside click
        return;
      }
      if (PublicMenuNode?.current?.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpandAvatar(false);
      setExpandPublicMenu(false);
    };

    const navigations = [
      // { name: "Dashboard", path: "/" },
      // { name: "Vendors", path: "/vendors" }
    ];

    const handleLogout = () => {
      dispatch(saveDeliverToAddress({}));
      authService.logout(history);
    };

    const handleDefaultAddress = (id) => {
      addresses = addresses.map((address, index) => {
        if (id === index) {
          return {
            ...address,
            isDefault: true,
          };
        } else {
          return {
            ...address,
            isDefault: false,
          };
        }
      });

      dispatch(updateUser({ addresses: addresses }))
        .unwrap()
        .then(({ message }) => {
          dispatch(saveDeliverToAddress(_.find(addresses, { isDefault: true })));
          dispatch(saveAddresses(addresses));
        });
    };

    const loginAction = () => {
      localStorage.setItem('current_path', window.location.pathname);
      window.location.href = loginUrl;
    };

    const getNavBarTitle = () => {
      if (path === '/search') {
        return 'Search Product';
      }
      return null;
    };

    const PublicContactUs = () => {
      return (
        <ModalContact show={modalOpen} title="Contact Us" closeable={false} confirmText="Back" confirmAction={() => setModulOpen(false)} defaultValues={formData}>
          <div className="w-full">
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <p>
                <a href={`https://wa.me/601153608166`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className="h-8 w-8 rounded-full object-cover" src={WhatsApp} alt="" />
                    <span className="text-xl text-black">+601153608166</span>
                  </p>
                </a>
              </p>
              <p>
                <p className="flex space-x-2">
                  <Image className="h-8 w-8 rounded-full object-cover" src={Mail} alt="" />
                  <span className="text-xl text-black">support@nubes.my</span>
                </p>
              </p>
            </div>
          </div>
        </ModalContact>
      );
    };

    const DrawerButton = ({ children, onClick, className }) => {
      return (
        <button
          onClick={onClick}
          className={classNames(
            className,
            'flex justify-start w-full mb-4 bg-white py-2 px-4 border text-neutral-500 text-md font-medium rounded-md shadow-sm transition-all duration-100 outline-none hover:bg-primary-50 hover:text-primary-500 hover:border-primary-100 focus:outline-none',
          )}
        >
          {children}
        </button>
      );
    };

    const ProfilePopOverItem = ({ children, onClick }) => {
      return (
        <button
          onClick={onClick}
          className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
        >
          {children}
        </button>
      );
    };

    // New pages layout
    if (isNewPage(path) && layoutShow) {
      return (
        <div className="bg-neutral-50">
          <div
            className={classNames(
              'flex-row w-full justify-between transition-all duration-300 px-3 md:shadow-md md:px-6 py-4 items-center lg:px-28',
              path === '/' ? 'absolute flex flex-row bg-transparent md:bg-white md:static' : 'flex bg-white', // Different display mode only for Home page
            )}
          >
            <Link to="/" className="hidden md:block">
              <NubesLogoV2 />
            </Link>

            {isMobile && (
              <div className="flex items-center relative w-full">
                {showBackButton ? (
                  <button
                    onClick={() => {
                      history.push('/');
                      // history.goBack();
                    }}
                    className="w-10 h-10 flex items-center justify-center z-10 border border-grey-400 rounded-full hover:bg-primary-100 hover:text-primary-600 hover:border-primary-400 focus:outline-none animate-all duration-200"
                  >
                    <Image className="w-5 h-auto" src={IcChevronLeft} alt="" />
                  </button>
                ) : (
                  // Hamburger menu
                  <button
                    onClick={() => setMobileDrawerOpen(true)}
                    className="flex xl:hidden items-center justify-center z-10 rounded-md text-primary-400 hover:bg-primary-100 hover:bg-opacity-30 focus:outline-none animate-all duration-200"
                  >
                    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                )}
                {showBackButton && getNavBarTitle() && (
                  <div className="w-full flex-grow text-center absolute left-1/2 transform -translate-x-1/2">
                    <p className="text-lg font-bold">{getNavBarTitle()}</p>
                  </div>
                )}
              </div>
            )}

            <div className={classNames('rounded-full hover:bg-primary-400 hover:bg-opacity-50 animate-all duration-200', path === '/' ? 'block md:hidden ' : 'hidden')}>
              <Link to="/search">
                <Image className="object-cover" src={IcSearch} alt="" />
              </Link>
            </div>

            {!isSessionExist() ? (
              <div className="hidden flex-row items-center gap-5 md:flex">
                <button
                  onClick={loginAction}
                  className="flex justify-center bg-white py-2 px-4 border text-neutral-500 text-md font-medium rounded-md shadow-sm transition-all duration-100 outline-none hover:bg-primary-50 hover:text-primary-500 hover:border-primary-100 focus:outline-none"
                >
                  Login / Sign Up
                </button>
                <TextButton
                  onClick={() => {
                    setModulOpen(true);
                  }}
                >
                  Contact Us
                </TextButton>
              </div>
            ) : (
              <div className="hidden md:block" ref={avatarNode}>
                <div className="flex flex-row gap-4 items-center">
                  {userApi?.payload?.name && <p>Hi, {userApi?.payload?.name}</p>}
                  <button type="button" onClick={() => setExpandAvatar(!expandAvatar)} className="flex items-center">
                    {userApi?.profilePicture?.path?.m ? (
                      <Image className="h-8 w-8 rounded-full object-cover" src={userApi?.profilePicture?.path?.m} alt="" />
                    ) : (
                      <div className="flex items-center h-7 w-7 rounded-full overflow-hidden bg-primary-100 focus:outline-none">
                        <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
                <Transition
                  show={expandAvatar}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="absolute right-0 w-56 mt-2 origin-top-right text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1" role="none">
                      <ProfilePopOverItem
                        onClick={() => {
                          history.push('/profile');
                        }}
                      >
                        <UserIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        My profile
                      </ProfilePopOverItem>
                      <ProfilePopOverItem
                        onClick={() => {
                          history.push('/addresses');
                        }}
                      >
                        <PinIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        My addresses
                      </ProfilePopOverItem>
                      <ProfilePopOverItem
                        onClick={() => {
                          history.push('/orders');
                        }}
                      >
                        <ShoppingBagIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        My orders
                      </ProfilePopOverItem>
                    </div>
                    <div className="px-1 py-1" role="none">
                      <ProfilePopOverItem
                        onClick={() => {
                          history.push(userApi?.hasSetupStore ? '/store' : '/store/register');
                        }}
                      >
                        <BuildingStoreIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        <div>
                          {userApi?.hasSetupStore ? 'My store' : 'Register as store'}
                          {formData?.data?.status === 'REGISTER' && formData?.data?.storeName == '' && <p className="text-xs text-gray-400">Open new stores</p>}
                          {formData?.data?.status === 'REGISTER' && formData?.data?.storeName !== '' && <p className="text-xs text-gray-400">Not complete</p>}
                          {formData?.data?.status === 'PENDING' && <p className="text-xs text-gray-400">Pending Approval</p>}
                        </div>
                      </ProfilePopOverItem>
                    </div>
                    <div className="px-1 py-1" role="none">
                      <ProfilePopOverItem onClick={() => setModulOpen(true)}>
                        <MailIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        Contact Us
                      </ProfilePopOverItem>
                    </div>
                    <div className="px-1 py-1" role="none">
                      <ProfilePopOverItem onClick={handleLogout}>
                        <LogOutIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        Log out
                      </ProfilePopOverItem>
                    </div>
                  </div>
                </Transition>
              </div>
            )}
          </div>
          <main>{children}</main>
          <Drawer
            title={
              <Link to="/" className="flex items-center justify-center">
                <NubesLogo className="h-10 w-auto" />
                <span className="ml-4 text-3xl font-logo text-primary-500 uppercase">Nubes.my</span>
              </Link>
            }
            closable={true}
            placement={'left'}
            onClose={() => {
              setMobileDrawerOpen(false);
            }}
            open={mobileDrawerOpen}
            key={'left'}
          >
            <div className="flex flex-col items-center ">
              {!isSessionExist() && (
                <DrawerButton
                  onClick={() => {
                    loginAction();
                    setMobileDrawerOpen(false);
                  }}
                  className={'w-full mb-4'}
                >
                  Login / Sign Up
                </DrawerButton>
              )}

              {isSessionExist() && (
                <div className="w-full">
                  <DrawerButton
                    onClick={() => {
                      setMobileDrawerOpen(false);
                      history.push('/profile');
                    }}
                  >
                    <UserIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                    My profile
                  </DrawerButton>
                  <DrawerButton
                    onClick={() => {
                      setMobileDrawerOpen(false);
                      history.push('/addresses');
                    }}
                  >
                    <PinIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                    My addresses
                  </DrawerButton>
                  <DrawerButton
                    onClick={() => {
                      setMobileDrawerOpen(false);
                      history.push('/orders');
                    }}
                  >
                    <ShoppingBagIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                    My orders
                  </DrawerButton>
                  <DrawerButton
                    onClick={() => {
                      setMobileDrawerOpen(false);
                      history.push(userApi?.hasSetupStore ? '/store' : '/store/register');
                    }}
                  >
                    <BuildingStoreIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                    <div className="flex flex-col items-start">
                      {userApi?.hasSetupStore ? 'My store' : 'Register as store'}
                      {formData?.data?.status === 'REGISTER' && formData?.data?.storeName == '' && <p className="text-xs text-gray-400">Open new stores</p>}
                      {formData?.data?.status === 'REGISTER' && formData?.data?.storeName !== '' && <p className="text-xs text-gray-400">Not complete</p>}
                      {formData?.data?.status === 'PENDING' && <p className="text-xs text-gray-400">Pending Approval</p>}
                    </div>
                  </DrawerButton>
                </div>
              )}
              <DrawerButton
                onClick={() => {
                  setModulOpen(true);
                  setMobileDrawerOpen(false);
                }}
              >
                <MailIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                Contact Us
              </DrawerButton>
              {isSessionExist() && (
                <DrawerButton onClick={handleLogout}>
                  <LogOutIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                  Log out
                </DrawerButton>
              )}
            </div>
          </Drawer>
          <PublicContactUs />
        </div>
      );
    }

    return (
      <div className="min-h-screen bg-gray-100">
        {tokenService?.getToken()?.impersonate_email && (
          <nav class=" bg-red-100">
            <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div class="relative flex h-16 items-center justify-between">
                <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div class="flex flex-shrink-0 items-center">Access as {tokenService.getToken().impersonate_email}</div>
                  <button
                    onClick={handleLogout}
                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                    id="logout"
                    role="menuitem"
                    tabIndex="-1"
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          </nav>
        )}

        {layoutShow && (
          <>
            <nav className="bg-primary-600">
              <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <button
                      type="button"
                      onClick={() => setExpandMenu(!expandMenu)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-amber-100 hover:text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      aria-controls="mobile-menu"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        className={classNames(expandMenu ? 'hidden' : 'block', 'h-6 w-6')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                      <svg
                        className={classNames(expandMenu ? 'block' : 'hidden', 'h-6 w-6')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to="/" className="flex items-center">
                        <NubesLogo className="h-10 w-auto" />
                        <span className="hidden sm:block ml-4 text-4xl font-logo text-primary-100 uppercase">Nubes Food </span>
                      </Link>
                    </div>
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                        {navigations.map((nav, index) => (
                          <a
                            key={index}
                            href={nav.path}
                            className={classNames(
                              nav.path === path ? 'bg-primary-600 text-amber-100' : 'text-white hover:bg-primary-700 hover:text-amber-100',
                              'px-3 py-2 rounded-md text-sm font-medium',
                            )}
                          >
                            {nav.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <div className={classNames(tokenService.getToken() ? 'hidden' : 'hidden sm:flex sm:flex-1 sm:items-center sm:justify-end sm:space-x-6')}>
                      <a onClick={loginAction} className="text-sm font-medium text-amber-100 hover:text-white">
                        Login / Register
                      </a>

                      <div className="ml-3 z-20 relative inline-block text-left" ref={PublicMenuNode}>
                        <button type="button" onClick={() => setExpandPublicMenu(!expandPublicMenu)} className="flex items-center">
                          <div className="inline-flex items-center justify-center p-2 rounded-md text-amber-100 hover:text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Open main menu</span>
                            <svg
                              className={classNames(expandPublicMenu ? 'hidden' : 'block', 'h-6 w-6')}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg
                              className={classNames(expandPublicMenu ? 'block' : 'hidden', 'h-6 w-6')}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </button>
                        <Transition
                          show={expandPublicMenu}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <div
                            className="absolute right-0 w-56 mt-2 origin-top-right text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            id="user-panel-menu-items"
                            role="menu"
                            tabIndex="0"
                          >
                            <div className="px-1 py-1" role="none">
                              <Form onSubmit={() => setModulOpen(true)}>
                                <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none">
                                  <MailIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                                  Contact Us
                                </button>
                              </Form>
                            </div>
                          </div>
                        </Transition>
                      </div>
                    </div>

                    {userApi?.deliverToAddress?.name ? (
                      <Popover>
                        <Popover.Button>
                          <div className="text-left text-primary-100 sm:pr-2 leading-tight">
                            <span className="text-sm">Deliver to:</span>
                            <p className="flex text-sm font-medium text-white">
                              {userApi?.deliverToAddress?.name?.length > 12 ? (
                                <div>{userApi?.deliverToAddress?.name.substring(0, 12)}...</div>
                              ) : (
                                <div>{userApi?.deliverToAddress?.name}</div>
                              )}
                              <ShareIcon className="pl-1 h-5 w-5 flex-shrink-0" />
                            </p>
                          </div>
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 w-64 px-4 mt-3 sm:px-0 right-0">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-2 bg-gray-50 divide-y">
                              <div className="px-1 py-1">
                                {userApi?.addresses.map((address, index) => (
                                  <Popover.Button
                                    key={index}
                                    onClickCapture={() => handleDefaultAddress(index)}
                                    className="w-full flex items-center justify-between p-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none"
                                  >
                                    <span className="flex items-center text-gray-700">{address.name}</span>
                                    {address.isDefault && (
                                      <svg className="w-5 h-5 flex-shrink-0 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          fillRule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                    )}
                                  </Popover.Button>
                                ))}
                              </div>
                              <div className="px-1 py-1">
                                <Popover.Button className="w-full flex items-center justify-between p-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none">
                                  <Link to="/addresses" className="flex items-center text-gray-700">
                                    Manage addresses
                                  </Link>
                                </Popover.Button>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Popover>
                    ) : (
                      <div className={classNames(!tokenService.getToken() ? 'hidden' : 'relative')}>
                        <Link
                          to="/addresses"
                          className="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-600 bg-primary-200 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                          <svg className="hidden sm:block -ml-1 mr-2 h-5 w-5 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
                          </svg>
                          <span className="hidden sm:block">Add new address</span>
                          <span className="block sm:hidden">New address</span>
                        </Link>
                        {tooltipAddressValue && (
                          <div className="absolute top-full left-1/2 mt-2.5 pt-1 -translate-x-1/2">
                            <Tooltip from="bottom" bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                              Add new address to get started
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}

                    <div className={classNames(tokenService.getToken() ? 'hidden sm:block' : 'hidden', 'ml-3 z-20 relative inline-block text-left')} ref={avatarNode}>
                      <button type="button" onClick={() => setExpandAvatar(!expandAvatar)} className="flex items-center">
                        {userApi?.profilePicture?.path?.m ? (
                          <Image className="h-8 w-8 rounded-full object-cover" src={userApi?.profilePicture?.path?.m} alt="" />
                        ) : (
                          <div className="flex items-center h-7 w-7 rounded-full overflow-hidden bg-primary-100 focus:outline-none">
                            <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </div>
                        )}
                      </button>
                      <Transition
                        show={expandAvatar}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="absolute right-0 w-56 mt-2 origin-top-right text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          id="user-panel-menu-items"
                          role="menu"
                          tabIndex="0"
                        >
                          <div className="px-1 py-1" role="none">
                            <Link
                              to="/profile"
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <UserIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                              My profile
                            </Link>
                            <Link
                              to="/addresses"
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <PinIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                              My addresses
                            </Link>
                            <Link
                              to="/orders"
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <ShoppingBagIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                              My orders
                            </Link>
                          </div>
                          <div className="px-1 py-1" role="none">
                            <Link
                              to={userApi?.hasSetupStore ? '/store' : '/store/register'}
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <BuildingStoreIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                              <div>
                                {userApi?.hasSetupStore ? 'My store' : 'Register as store'}
                                {formData?.data?.status === 'REGISTER' && formData?.data?.storeName == '' && <p className="text-xs text-gray-400">Open new stores</p>}
                                {formData?.data?.status === 'REGISTER' && formData?.data?.storeName !== '' && <p className="text-xs text-gray-400">Not complete</p>}
                                {formData?.data?.status === 'PENDING' && <p className="text-xs text-gray-400">Pending Approval</p>}
                              </div>
                            </Link>
                          </div>
                          <div className="px-1 py-1" role="none">
                            <Form onSubmit={() => setModulOpen(true)}>
                              <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none">
                                <MailIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                                Contact Us
                              </button>
                            </Form>
                          </div>
                          <div className="px-1 py-1" role="none">
                            <button
                              onClick={handleLogout}
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <LogOutIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                              Log out
                            </button>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Mobile menu, show/hide based on menu state. --> */}
              <div className={classNames(expandMenu ? 'block' : 'hidden', 'sm:hidden')} id="mobile-menu">
                <div className="px-2 pb-3 space-y-1">
                  {navigations.map((nav, index) => (
                    <a
                      key={index}
                      href={nav.path}
                      className={classNames(
                        nav.path === path ? 'bg-primary-600 text-amber-100' : 'text-white hover:bg-primary-700 hover:text-amber-100',
                        'block px-3 py-2 rounded-md text-base font-medium',
                      )}
                    >
                      {nav.name}
                    </a>
                  ))}
                </div>
                <div className="block" aria-hidden="true">
                  <div className="border-t border-primary-500"></div>
                </div>
                <div className={classNames(tokenService.getToken() ? 'hidden' : 'block', 'px-1 py-1')}>
                  <a onClick={loginAction} className="text-white hover:bg-primary-700 hover:text-amber-100 block px-2 py-3 rounded-md text-base font-medium">
                    Login / Register
                  </a>
                  <Form onSubmit={() => setModulOpen(true)}>
                    <button className="text-white hover:bg-primary-700 hover:text-amber-100 block px-2 py-3 rounded-md text-base font-medium">Contact Us</button>
                  </Form>
                </div>
                <div className={classNames(tokenService.getToken() ? 'block' : 'hidden', 'divide-y divide-primary-500')}>
                  <div className="px-1 py-1" role="none">
                    <Link
                      onClick={() => setExpandMenu(!expandMenu)}
                      to="/profile"
                      className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium"
                    >
                      <UserIcon className="flex-shrink-0 h-6 w-6 mr-2" />
                      My profile
                    </Link>
                    <Link
                      onClick={() => setExpandMenu(!expandMenu)}
                      to="/addresses"
                      className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium"
                    >
                      <PinIcon className="flex-shrink-0 h-6 w-6 mr-2" />
                      My addresses
                    </Link>
                    <Link
                      onClick={() => setExpandMenu(!expandMenu)}
                      to="/orders"
                      className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium"
                    >
                      <ShoppingBagIcon className="flex-shrink-0 h-6 w-6 mr-2" />
                      My orders
                    </Link>
                  </div>
                  <div className="px-1 py-1" role="none">
                    <Link
                      onClick={() => setExpandMenu(!expandMenu)}
                      to={userApi?.hasSetupStore ? '/store' : '/store/register'}
                      className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium"
                    >
                      <BuildingStoreIcon className="flex-shrink-0 h-5 w-5 mr-2" />

                      {userApi?.hasSetupStore ? 'My store' : 'Register new store'}
                      {formData?.data?.status === 'REGISTER' && formData?.data?.storeName == '' && <p className="text-s font-thin text-white">&nbsp; (Open new stores)</p>}
                      {formData?.data?.status === 'REGISTER' && formData?.data?.storeName !== '' && <p className="text-s font-thin text-white">&nbsp; (Not complete)</p>}
                      {formData?.data?.status === 'PENDING' && <p className="text-s font-thin text-white">&nbsp; (Pending Approval)</p>}
                    </Link>
                  </div>
                  <div className="px-1 py-1" role="none">
                    <Form
                      onSubmit={() => {
                        setModulOpen(true);
                        setExpandMenu(!expandMenu);
                      }}
                    >
                      <button className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium">
                        <MailIcon className="flex-shrink-0 h-5 w-5 mr-2" />
                        Contact Us
                      </button>
                    </Form>
                    <PublicContactUs />
                  </div>
                  <div className="px-1 py-1" role="none">
                    <button
                      type="button"
                      onClick={() => authService.logout(history)}
                      className="flex items-center w-full text-white hover:bg-primary-700 hover:text-amber-100 px-2 py-3 rounded-md text-base font-medium"
                    >
                      <LogOutIcon className="flex-shrink-0 h-6 w-6 mr-2" />
                      Log out
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </>
        )}
        <main>{children}</main>
      </div>
    );
  },
};

export default Layout;
